import React from "react";
import {Box, Stack} from '@mui/system';
import {Grid, Typography} from '@mui/material';
import Button from "@mui/material/Button";

const drawerWidth = 240;
const CampaignManager = () => {
    return (
        <Box
            component="main"
            sx={{flexGrow: 1, p: 3, width: {sm: `calc(100% - ${drawerWidth}px)`}, marginTop: '46px'}}
        >
            <Grid xs={12}>
                <Typography variant={'h4'}>Campaign builder</Typography>
                <Stack gap={2} alignItems="center">
                    <Button variant="contained" color="salmon">
                        Salmon
                    </Button>
                    <Stack direction="row" gap={1}>
                        <Stack alignItems="center">
                            <Typography variant="body2">light</Typography>
                            <Box sx={{bgcolor: 'salmon.light', width: 40, height: 20}}/>
                        </Stack>
                        <Stack alignItems="center">
                            <Typography variant="body2">main</Typography>
                            <Box sx={{bgcolor: 'salmon.main', width: 40, height: 20}}/>
                        </Stack>
                        <Stack alignItems="center">
                            <Typography variant="body2">dark</Typography>
                            <Box sx={{bgcolor: 'salmon.dark', width: 40, height: 20}}/>
                        </Stack>
                    </Stack>
                </Stack>
            </Grid>
        </Box>
    )
}

export default CampaignManager