import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Unstable_Grid2";
import ConversationList from "../ConversationList/ConversationList";
import ChatMsg from "./ChatMsg";
import _ from "lodash";

const ConversationMessages = ({ onError, campaign, account }) => {
    const [messages, setMessages] = useState([]);
    const [conversation, setConversation] = useState('');

    const onSelectConversation = (conversation, messages) => {
        setMessages(messages);
        setConversation(conversation);
    };

    useEffect(() => {
        if (!account) {
            setMessages([]);
        }
    }, [account]);

    useEffect(() => {
        if (!campaign) {
            setMessages([]);
        }
    }, [campaign]);

    // Manejador para actualizar el estado de los mensajes
    const updateMessageStatus = (messageId) => {
        setMessages((prevMessages) =>
            prevMessages.map((message) =>
                message.id === messageId ? { ...message, status: 'APPROVED' } : message
            )
        );
    };

    return (
        <Box sx={{ minWidth: 275 }}>
            <Grid container spacing={2}>
                {(!_.isEmpty(campaign)) ? (
                    <Grid xs={4} sm={4} md={4} lg={3} sx={{ display: campaign ? 'flex' : 'none' }}>
                        <ConversationList campaign={campaign} account={account} onError={onError} onSelect={onSelectConversation} />
                    </Grid>
                ) : ('')}
                {(!_.isEmpty(messages)) ? (
                    <Grid xs={8} sm={8} md={8} lg={9}>
                        {messages.map((item, index) => (
                            <ChatMsg
                                key={item.id || index}
                                avatar={""}
                                side={item.role === 'CONTACT' ? 'left' : 'right'}
                                messages={[item]}
                                campaign={campaign}
                                onApprove={updateMessageStatus} // Pasar el manejador
                            />
                        ))}
                    </Grid>
                ) : ('')}
            </Grid>
        </Box>
    );
};

export default ConversationMessages;
