import {createSvgIcon} from "@mui/material";
import * as React from "react";

const Cai3p0Icon = createSvgIcon(
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 500.000000 500.000000"
         preserveAspectRatio="xMidYMid meet">

        <defs>
            <linearGradient
                id="linear-gradient"
                x1="30.63"
                x2="326.92"
                y1="15.72"
                y2="312.01"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.2" stopColor="#b658ff"></stop>
                <stop offset="0.85" stopColor="#0e73ff"></stop>
            </linearGradient>
            <linearGradient
                xlinkHref="#linear-gradient"
                id="linear-gradient-2"
                x1="37.2"
                x2="117.54"
                y1="16.45"
                y2="96.78"
            ></linearGradient>
            <linearGradient
                xlinkHref="#linear-gradient"
                id="linear-gradient-3"
                x1="145.66"
                x2="237.11"
                y1="46.27"
                y2="137.71"
            ></linearGradient>
            <linearGradient
                xlinkHref="#linear-gradient"
                id="linear-gradient-4"
                x1="79.44"
                x2="98.01"
                y1="82.63"
                y2="101.2"
            ></linearGradient>
            <linearGradient
                xlinkHref="#linear-gradient"
                id="linear-gradient-5"
                x1="49.7"
                x2="145.8"
                y1="94.25"
                y2="190.34"
            ></linearGradient>
            <linearGradient
                xlinkHref="#linear-gradient"
                id="linear-gradient-6"
                x1="202"
                x2="224.22"
                y1="131.1"
                y2="153.32"
            ></linearGradient>
            <linearGradient
                xlinkHref="#linear-gradient"
                id="linear-gradient-7"
                x1="156.31"
                x2="251.47"
                y1="146.74"
                y2="241.9"
            ></linearGradient>
            <linearGradient
                xlinkHref="#linear-gradient"
                id="linear-gradient-8"
                x1="75.5"
                x2="97.72"
                y1="183.21"
                y2="205.43"
            ></linearGradient>
            <linearGradient
                xlinkHref="#linear-gradient"
                id="linear-gradient-9"
                x1="79.82"
                x2="198.79"
                y1="188.31"
                y2="307.28"
            ></linearGradient>
            <linearGradient
                xlinkHref="#linear-gradient"
                id="linear-gradient-10"
                x1="152.7"
                x2="247.31"
                y1="246.47"
                y2="341.08"
            ></linearGradient>
            <linearGradient
                xlinkHref="#linear-gradient"
                id="linear-gradient-11"
                x1="82.05"
                x2="101.84"
                y1="283.8"
                y2="303.59"
            ></linearGradient>
        </defs>
        <path
            fill="url(#linear-gradient)"
            strokeWidth="0"
            d="M194.64 29.48c-4.32 12.16-17.3 18.15-29.39 13.52-6.05-2.33-10.38-6.84-12.97-13.55-.13-.35-.39-.52-.76-.52H62.01c-.38 0-.66.17-.83.5-2.09 4.02-5.15 6.71-9.18 8.06-11.89 4-23.77-6.51-20.76-18.96 3.49-14.41 22.91-16.77 29.81-3.59.26.49.67.74 1.22.74h88.65c.64 0 1.22-.38 1.47-.97 2.92-6.77 7.42-11.22 13.49-13.36 12.03-4.24 24.41 1.87 28.74 13.69q.21.57.81.63c48.72 4.87 110.22 26.82 116.59 83.82.6 5.39.84 11.35.71 17.88-.02 1.17.27 2.21.25 3.39-.13 7.22.21 14.5.24 21.55.03 8.3.38 16.59 1.04 24.87.44 5.57 1.61 10.57 3.52 14.98 1.04 2.4 3.14 5.43 6.29 9.1 4.12 4.79 8.35 9.77 12.7 14.96 6.45 7.69 14.44 20.91 1.9 27.64-5.29 2.84-10.26 6.2-14.04 10.74-.29.35-.3.85-.02 1.21 6.2 7.86 3.43 17.65-4.29 23.34-.33.25-.34.51-.02.78 2.95 2.48 4.26 5.62 3.91 9.43-.62 6.69-5.19 11.41-11.75 13.05-.41.1-.54.35-.39.74 6.58 16.76 3.87 40.23-17.09 45.14-3.22.75-8.39.72-15.52-.1-11.01-1.28-21.94-2.38-32.97-.64-13.63 2.15-23.72 9.26-30.29 21.32-.18.33-.13.62.16.86 5.21 4.32 8.07 9.64 8.59 15.96 1.57 19.29-20.42 31.37-35.74 19.67-16.99-12.98-8.13-39.95 13.23-40.24.33-.01.63-.2.78-.5 8.43-17.89 22.68-28.07 42.74-30.54 12.66-1.56 25.36-.13 38.37 1.45 4.28.53 10.02.36 13.31-2.75 6.14-5.82 4.77-17.17 2.35-24.84-.94-2.96-1.25-6.21-.94-9.75.36-4.04 3.08-8.26 7.45-8.33 1.32 0 2.59-.19 3.82-.54.24-.07.31-.38.13-.55-5.55-5.31-7.77-14.03-.15-18.93 1.83-1.18 4.45-2.17 4.43-4.76 0-.37-.13-.69-.4-.94-6.12-5.78-3.66-13.83 1.42-19.4 4.16-4.55 9.26-8.46 15.3-11.71.53-.29.71-.97.39-1.48-5.54-8.72-12.54-16-19.14-23.85-6.11-7.27-9.67-15.62-10.69-25.05q-1.98-18.285-1.59-35.97c.01-.82-.31-1.61-.31-2.34.07-12.28-.15-22.52-.65-30.71-2.02-33.01-28.47-53.71-57.3-64.21-14.55-5.31-29.97-8.8-46.26-10.49-.43-.05-.71.14-.86.55"
        ></path>
        <path
            fill="url(#linear-gradient-2)"
            strokeWidth="0"
            d="M116.68 63.19c-35.15-.09-71.94-.08-110.38.03-8.36.03-8.54-13.28.32-13.29 37.62-.02 74.37-.02 110.26 0 .39 0 .68-.17.85-.52 3.81-7.62 11.95-11.59 20.2-9.31 14.64 4.03 17.22 23.4 4.36 31.2-8.9 5.39-19.86 1.87-24.45-7.39-.24-.48-.63-.72-1.16-.72"
        ></path>
        <path
            fill="url(#linear-gradient-3)"
            strokeWidth="0"
            d="M140.97 85.43h88.04c.43 0 .73-.2.88-.61 3.3-8.51 9.31-13.49 18.02-14.92 12.23-2.02 23.21 6.43 25.29 18.58 2.23 12.97-7.49 25.05-20.54 25.84-10.3.63-19.23-5.41-22.72-15.11-.13-.35-.38-.53-.75-.53h-88.18c-.37 0-.63.16-.79.49-3.96 8.23-12.81 12.15-21.4 9.16-10.98-3.8-15.44-17.09-8.31-26.64 7.72-10.34 24.06-9.25 29.49 3.11.19.42.51.63.97.63"
        ></path>
        <path
            fill="url(#linear-gradient-4)"
            strokeWidth="0"
            d="M101.86 91.92c0 7.25-5.88 13.13-13.13 13.13S75.6 99.17 75.6 91.92s5.88-13.13 13.13-13.13 13.13 5.88 13.13 13.13"
        ></path>
        <path
            fill="url(#linear-gradient-5)"
            strokeWidth="0"
            d="M140.98 135.36q2.25-6.885 7.77-10.8c8.29-5.9 16.77-6.3 25.42-1.21 2.86 1.69 5.34 4.28 7.43 7.78 5.15 8.6 3.4 19.97-3.54 26.95-11.79 11.84-31.76 7-37.07-8.59-.13-.39-.41-.58-.82-.58-42.21.03-85.33.04-129.36.01-3.95-.01-6.9-3.62-6.32-7.47.72-4.72 4.46-5.83 8.65-5.83 43.57.07 86.04.09 127.41.05.19 0 .36-.12.43-.31"
        ></path>
        <path
            fill="url(#linear-gradient-6)"
            strokeWidth="0"
            d="M228.82 142.21c0 8.68-7.03 15.71-15.71 15.71s-15.71-7.03-15.71-15.71 7.03-15.71 15.71-15.71 15.71 7.03 15.71 15.71"
        ></path>
        <path
            fill="url(#linear-gradient-7)"
            strokeWidth="0"
            d="M161.62 201.5c-2.73 7.34-7.53 12.05-14.41 14.12-14.18 4.28-28.63-5.48-29.09-20.5-.51-16.47 15.3-27.08 30.3-21.71 6.17 2.21 10.6 6.8 13.28 13.75.13.35.39.52.76.52h82.96c.33 0 .55-.15.67-.45q4.245-10.965 14.1-14.1c5.81-1.85 11.44-1.51 16.87 1.02 7.6 3.53 12.97 11.9 12.67 20.26-.34 9.26-4.57 15.95-12.68 20.06-5.07 2.57-10.56 2.95-16.47 1.12-6.91-2.14-11.74-6.87-14.51-14.2-.11-.31-.33-.46-.66-.46h-82.97c-.41 0-.68.19-.82.57"
        ></path>
        <path
            fill="url(#linear-gradient-8)"
            strokeWidth="0"
            d="M102.32 194.32c0 8.68-7.03 15.71-15.71 15.71S70.9 203 70.9 194.32s7.03-15.71 15.71-15.71 15.71 7.03 15.71 15.71"
        ></path>
        <path
            fill="url(#linear-gradient-9)"
            strokeWidth="0"
            d="M204.47 254.43H61.51q-.615 0-.87.57c-3.64 8.2-12.5 11.78-20.79 9.34-9.52-2.81-14.59-13.03-11.23-22.35 5.38-14.88 25.41-15.41 32.02-1.22.13.27.34.41.64.41h143.41c.35 0 .59-.17.7-.51 2.87-8.52 10.51-14.52 19.5-15.17 11.52-.83 21.84 7.04 23.8 18.6 2.51 14.8-10.21 28.07-25.07 25.83-8.79-1.32-14.9-6.29-18.31-14.92-.15-.39-.43-.58-.84-.58"
        ></path>
        <path
            fill="url(#linear-gradient-10)"
            strokeWidth="0"
            d="M156.56 301c-2.95 7.15-7.81 11.67-14.6 13.55-16.4 4.54-31.74-10.58-26.6-27 6.36-20.29 34.01-20.38 41.2-.68.07.19.25.31.45.31h83.94c.31 0 .59-.19.7-.48 3.04-8.07 8.65-13.01 16.83-14.84 6.68-1.49 14.34.55 19.31 5.18 6.42 5.99 8.72 13.23 6.91 21.74-1.04 4.87-3.45 8.87-7.22 11.99-12.49 10.35-30.05 5.26-35.84-9.85-.13-.33-.37-.49-.72-.49h-83.51c-.41 0-.69.19-.85.57"
        ></path>
        <path
            fill="url(#linear-gradient-11)"
            strokeWidth="0"
            d="M105.94 293.7c0 7.73-6.26 13.99-13.99 13.99s-13.99-6.26-13.99-13.99 6.26-13.99 13.99-13.99 13.99 6.26 13.99 13.99"
        ></path>
    </svg>,
    'Plus',
);

export default Cai3p0Icon;