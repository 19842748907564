import './App.css';
import * as React from 'react';
import {withAuthenticator} from "@aws-amplify/ui-react";
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from "./components/navbar/navbar";
import {Outlet} from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";

function App() {
    return (
        <Grid2 container>
            <CssBaseline/>
            <Navbar/>
            <Outlet/>
        </Grid2>
    );
}

export default withAuthenticator(App, {
    hideSignUp: true,
    includeGreetings: true
});
