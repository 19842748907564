import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";
import { API } from "aws-amplify";
import { putApproveMessage } from "../../graphql/queries";

const ApproveDialog = ({ show, data, handleClose, onReturn }) => {

    const handleSubmit = async () => {
        const params = {
            message_id: data.id,
            campaign_id: data.campaign,
        };

        try {
            const response = await API.graphql({
                query: putApproveMessage,
                variables: params,
                authMode: "AMAZON_COGNITO_USER_POOLS",
            });
            console.log("response: ", response);
        } catch (error) {
            console.error("Error sending approve message data:", error);
        }

        onReturn(data.id); // Llamar al manejador de aprobación

        handleClose();
    };

  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle>
        <Typography fontWeight="fontWeightBold">Do you want to approve this message?</Typography>
      </DialogTitle>
      <DialogActions sx={{ marginRight: "15px" }}>
        <Button sx={{ color: "#015d86" }} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          sx={{
            color: "white",
            backgroundColor: "#015d86",
            "&:hover": {
              backgroundColor: "gray",
            },
          }}
          onClick={handleSubmit}
        >
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveDialog;
