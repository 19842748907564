import {createTheme} from "@mui/material/styles";


let theme = createTheme({
    // Theme customization goes here as usual, including tonalOffset and/or
    // contrastThreshold as the augmentColor() function relies on these
    components: {
        // Name of the component
        palette: {
            primary: {
                main: '#038fcd'
            },
            secondary: {
                main: 'purple' //'#05425AFF'
            }
        },
    },
});

export const appTheme = createTheme(theme, {

    // Name of the component
    palette: {
        salmon: theme.palette.augmentColor({
            color: {
                main: '#FF5733',
            },
            name: 'salmon',
        }),
    },

});