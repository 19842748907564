import PropTypes from "prop-types";
import React, {useState} from "react";
import _ from "lodash";

import {Avatar, Badge, Grid, Typography} from "@mui/material";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import cx from "clsx";
import {ChatMsgStyle} from "./defaultChatMsg.styles";
import {ThemeProvider} from "@mui/material/styles";
import ApproveDialog from "./ApproveDialog";

const ChatMsg = (props) => {
    const {
        avatar,
        messages,
        campaign,
        side,
        GridContainerProps,
        GridItemProps,
        AvatarProps,
        getTypographyProps,
        onApprove
    } = props;

    const [approveData, setApprovekData] = useState({});
    const [openApproveDialog, setOpenApproveDialog] = useState(false);

    const handleHiddenDialog = () => {
        setOpenApproveDialog(false);
    };


    const handleThumbClick = (id, messages, i) => {

        const approveDataObj = {
            "id": id,
            "campaign": campaign
        }

        console.log(i)

        setApprovekData(approveDataObj)

        setOpenApproveDialog(true);

    };

    const handleApprove = () => {
        onApprove(approveData.id); // Llamar al manejador de aprobación
    };

    const classes = ChatMsgStyle
    const attachClass = (index) => {
        if (index === 0) {
            return classes[`${side}First`];
        }
        if (index === messages.length - 1) {
            return classes[`${side}Last`];
        }
        return "";
    };
    return (
        <ThemeProvider theme={ChatMsgStyle}>
            <Grid
                container
                spacing={1}
                justify={side === "right" ? "flex-end" : "flex-start"}
                {...GridContainerProps}
            >
                {side === "left" && (
                    <Grid item xs={1} {...GridItemProps}>
                        <Avatar
                            sx={{display: {xs: 'none', sm: 'none', md: 'flex'}}}
                            src={avatar}
                            {...AvatarProps}
                            className={cx(classes.avatar, AvatarProps.className)}
                        />
                    </Grid>
                )}
                <Grid item xs={11}>
                    {messages.map((msg, i) => {
                        const TypographyProps = getTypographyProps(msg, i, props);
                        let combined_styles = [classes.msg,
                            classes[side],
                            attachClass(i),
                            TypographyProps.className]
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={msg.id || i} className={classes[`${side}Row`]}>
                                <Badge color="secondary" badgeContent={msg.channel}>
                                    <Typography
                                        align={side}
                                        {...TypographyProps}
                                        sx={combined_styles}
                                    >
                                        "{msg.message}"
                                    </Typography>
                                    {msg.channel === "EMAIL" && _.toLower(msg.role).includes('agent') ? (
                                        msg.status === "PENDING_SEND" ? (
                                            <ThumbUpOutlinedIcon
                                                sx={{fontSize: 16, marginLeft: 0.5, marginTop: 1.5}}
                                                color="primary"
                                                onClick={() => handleThumbClick(msg.id, messages, i)}
                                            />

                                        ) : (
                                            <MarkEmailReadIcon
                                                sx={{fontSize: 16, marginLeft: 0.5, marginTop: 1.5}}
                                                color="primary"
                                            />
                                        )
                                    ) : null}
                                </Badge>
                            </div>
                        );
                    })}
                </Grid>
                {side === " right" && (
                    <Grid item xs={1} {...GridItemProps}>
                        <Avatar
                            sx={{display: {xs: 'none', sm: 'none', md: 'flex'}}}
                            src={avatar}
                            {...AvatarProps}
                            className={cx(classes.avatar, AvatarProps.className)}
                        />
                    </Grid>
                )}
            </Grid>
            <ApproveDialog
                show={openApproveDialog}
                data={approveData}
                handleClose={handleHiddenDialog}
                onReturn={handleApprove}
            />
        </ThemeProvider>
    )
}

ChatMsg.propTypes = {
    avatar: PropTypes.string,
    messages: PropTypes.arrayOf(PropTypes.object),
    side: PropTypes.oneOf([" left", " right"]),
    GridContainerProps: PropTypes.shape({}),
    GridItemProps: PropTypes.shape({}),
    AvatarProps: PropTypes.shape({}),
    getTypographyProps: PropTypes.func
};
ChatMsg.defaultProps = {
    avatar: "",
    messages: [],
    side: " left",
    GridContainerProps: {},
    GridItemProps: {},
    AvatarProps: {},
    getTypographyProps: () => ({})
};

export default ChatMsg;
