import * as React from 'react';
import {useState} from 'react';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import {FormControl} from "@mui/material";

const CustomSelect = ({items, name, onSelect, loading}) => {
    const [value, setValue] = useState([]);

    const handleChange = (event) => {
        let item_value = event.target.value
        setValue(item_value);
        onSelect(event, item_value)
    };


    if (loading) {
        return (
            <CircularProgress/>
        )
    } else {
        return (<FormControl sx={{m: 1, minWidth: 120}} size="small">
            <InputLabel id="demo-select-small-label">{name}</InputLabel>
            <Select
                labelId="demo-select-small-label"
                id="select-small"
                value={value}
                label={name}
                onChange={handleChange}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {items.map((item, index) => (
                    <MenuItem value={item.id} id={'id' + item.id} key={'key' + item.id}>{item.name}</MenuItem>
                ))}
            </Select>
        </FormControl>)
    }

}

export default CustomSelect