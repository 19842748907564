import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Amplify} from 'aws-amplify';
import awsconfig from './aws-exports';
import "@aws-amplify/ui-react/styles.css";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ErrorPage from "./error-page";
import CampaignManager from "./pages/manager/manager";
import CampaignViewer from "./pages/viewer/viewer";
import {appTheme} from "./appTheme";
import {ThemeProvider} from "@mui/material/styles";
import CampaignBuilderPublic from "./pages/getStarted/getStarted";
import Kommunicate from "@kommunicate/kommunicate-chatbot-plugin";

Amplify.configure(awsconfig);

Kommunicate.init("2346b7bb10c0fbd58374bdbf2c12849ad", {
  automaticChatOpenOnNavigation: true,
  popupWidget: true
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={appTheme}>
        <BrowserRouter>
            <Routes>
                <Route path={'/'} element={<App/>} errorElement={<ErrorPage/>}>
                    <Route path='viewer' element={<CampaignViewer/>} errorElement={<ErrorPage/>}/>
                    <Route path="manager" element={<CampaignManager/>} errorElement={<ErrorPage/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
